<template>
  <div class="about container">
    <h1>Contactez-nous :</h1>
    <p>Un projet ? Un besoin d’étude ? Contactez-nous !</p>
    <v-card
        elevation="10"
        outlined
        dark
        style="padding: 25px; margin:25px;"
      >
        <v-form>
          <v-text-field
            v-model="name"
            label="Nom"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-textarea
            v-model="text"
            label="Votre message pour nous : "
          ></v-textarea>
          <vue-recaptcha v-if="rcpVar == ''" sitekey="6LeAdSIdAAAAAOAP1MKvBymbxvFr1sM4cy1DicG-" @verify="rcp"></vue-recaptcha>
          <a v-else :href="'https://nmagencement.fr/contactform.php?name='+name+'&email='+email+'&text='+text" class="cta1">Envoyer</a>
        </v-form>
      </v-card>
      <v-card
        elevation="10"
        outlined
        dark
        style="padding: 25px; margin:25px;"
      >
        <h2>NMAgencement</h2>
          <p>Téléphone : 07 66 25 18 64</p>
          <p>E-mail : <a class="w" href="mailto:contact@nmagencement.fr">contact@nmagencement.fr</a></p>
          <p>6 Allée de la chauvinière 35500 Vitré - FRANCE</p>
      </v-card>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';

export default({
  components:{VueRecaptcha},
  data(){
    return {
      rcpVar:"",
      name:"",
      email:"",
      text:"",
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    rcp(res){
      this.rcpVar = res;
    }
  }
})
</script>
<style scoped>
.w{
    color: white;
}
</style>
